import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51641ca5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "level-left" }
const _hoisted_2 = { class: "level-right" }
const _hoisted_3 = { class: "level-item" }
const _hoisted_4 = { class: "media" }
const _hoisted_5 = { class: "media-content" }
const _hoisted_6 = { class: "media-right" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "media"
}
const _hoisted_9 = { class: "media-content" }
const _hoisted_10 = { class: "card-title" }
const _hoisted_11 = { class: "media-right" }
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_page_header = _resolveComponent("el-page-header")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_side_menu = _resolveComponent("side-menu")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_ToggleSidebar = _resolveComponent("ToggleSidebar")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_PreviewRendererImg = _resolveComponent("PreviewRendererImg")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_container, { class: "screenHeight" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_header, { class: "level" }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, [
            _createVNode(_component_el_page_header, {
              class: "level-item",
              content: _ctx.$t('components.menu-sidebar.configuration.templates'),
              onBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
            }, null, 8, ["content"])
          ]),
          _createElementVNode("span", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.user?.firstname) + " " + _toDisplayString(_ctx.user?.lastname), 1)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          (_ctx.showMenu)
            ? (_openBlock(), _createBlock(_component_el_aside, {
                key: 0,
                class: "withFooterAndHeader",
                width: "20rem"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_scrollbar, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_side_menu, { "active-menu-item": "2-2" })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ToggleSidebar, {
            modelValue: _ctx.showMenu,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showMenu) = $event))
          }, null, 8, ["modelValue"]),
          _withDirectives((_openBlock(), _createBlock(_component_el_main, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.searchText,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchText) = $event)),
                    placeholder: _ctx.$t('views.template.search')
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: _ctx.createNew
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('views.template.create')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ]),
              _createVNode(_component_el_space, {
                wrap: "",
                style: {"align-items":"flex-start"},
                class: "productOverview"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterList, (model) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: model.modelId,
                      class: "products",
                      onClick: ($event: any) => (_ctx.displayModel(model.modelId, $event))
                    }, [
                      _createVNode(_component_PreviewRendererImg, {
                        width: '100%',
                        meshes: [
                {
                  thumbnail: model.product.name,
                  thumbnailUrl: _ctx.convertToUrl(model),
                },
              ]
                      }, null, 8, ["meshes"]),
                      (model.product)
                        ? (_openBlock(), _createElementBlock("h2", _hoisted_8, [
                            _createElementVNode("span", _hoisted_9, [
                              _createVNode(_component_el_tooltip, {
                                class: "box-item",
                                effect: "dark",
                                content: _ctx.productTitle(model.product.name),
                                placement: "top-start"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("h5", _hoisted_10, _toDisplayString(_ctx.productTitle(model.product.name)), 1)
                                ]),
                                _: 2
                              }, 1032, ["content"])
                            ]),
                            _createElementVNode("span", _hoisted_11, [
                              _createElementVNode("span", {
                                id: "deleteModel",
                                onClick: ($event: any) => (_ctx.deleteModel(model))
                              }, [
                                _createVNode(_component_font_awesome_icon, { icon: "trash" })
                              ], 8, _hoisted_12)
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ], 8, _hoisted_7))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })), [
            [_directive_loading, _ctx.modelsLoading]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}