<template>
  <el-container class="screenHeight">
    <el-header class="level">
      <span class="level-left">
        <el-page-header
          class="level-item"
          :content="$t(`enum.upload-progress.${activeUploadProgress}`)"
          @back="$router.back()"
        />
      </span>
      <span class="level-right">
        <span class="level-item">
          {{ user?.firstname }}
          {{ user?.lastname }}
        </span>
      </span>
    </el-header>
    <el-container>
      <el-main>
        <MeshLoader
          v-model:uploadProgress="activeUploadProgress"
          ref="meshLoader"
          :canSelect="true"
          :canTakeSnapshot="true"
          :canToggleOpacity="true"
          :directoryPath="user?.username"
          :isProductConfig="isProductConfig"
        ></MeshLoader>
      </el-main>
    </el-container>
    <Workflow
      class="workflow"
      :workflowModel="workflowModel"
      :fullscreenLoading="fullscreenLoading"
    />
  </el-container>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import MeshLoader from '@/components/three/MeshLoader.vue';
import { DesignType, FileTypeImage, FileTypeVideo, UploadProgress } from '@/types/enum/upload';
import * as LayoutUtility from '@/utils/layout';
import * as ModelService from '@/services/api/modelService';
import * as FormBuilderService from '@/services/api/formBuilderService';
import { Prop, Watch } from 'vue-property-decorator';
import Workflow from '@/components/workflow/Workflow.vue';
import { WorkflowModel } from '@/types/ui/WorkflowModel';
import Auth from '@/services/api/auth';
import { User } from '@/types/api/User/User';
import { makeWordToCapitalized } from '@/utils/string';
import ProductCard from '@/components/ProductCard.vue';
import { ProductVideoFiles, saveFileMetadataToAzureTables, TableField, blobUrlToFile, extractExtensionFromBase64, generateRandomFilename, uploadToAzure } from '@/utils/file';
import { Product } from '@/types/api/Model/Product';
import { MeshTreeData } from '@/types/ui/MeshTreeData';
import { Action, ElMessage, ElMessageBox } from 'element-plus';

@Options({
  components: {
    MeshLoader,
    Workflow,
  },
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class TemplateUpload extends Vue {
  @Prop() templateId!: number | null;

  workflowModel: WorkflowModel = new WorkflowModel(
    'upload-progress',
    UploadProgress
  );
  fullscreenLoading = false;

  authentication = Auth.getInstance();
  abortController = new AbortController();

  user: User | null = null;
  isProductConfig = false

  get activeUploadProgress(): UploadProgress {
    if (this.workflowModel.active < this.workflowModel.stepCount) {
      LayoutUtility.refresh();
      return Object.values(UploadProgress)[this.workflowModel.active];
    }
    return UploadProgress.Pivot;
  }

  set activeUploadProgress(value: UploadProgress) {
    this.workflowModel.active = Object.values(UploadProgress).indexOf(value);
  }

  mounted(): void {
    this.authentication.handleAuthentication().then(() => {
      //get user information
      console.log('this.authentication', this.authentication)
      this.user = this.authentication.user;
    });
  }

  // Watch untuk mendeteksi perubahan state isLoading
  @Watch('user', { immediate: true })
  onUserChanged(newValue: any[]) {
    console.log('user detected change:', newValue);
  }

  async prepareFormBuilderPayloads(configFields:any[]){
    const newFormFields = await Promise.all(configFields.map(async (c) => {
      let rootImage = c.image;
      let rootOptions = c.options
      if (c.imageType === 'file') {
        try {
          const ext = extractExtensionFromBase64(c.image)
          const filename = generateRandomFilename(ext)
          const file = await blobUrlToFile(c.image, filename)
          const fileAzureUrl = await uploadToAzure({
            file:file,
            direcatoryPath:'',
            containerName:'formbuilder',
            type: "formbuilder"
          })
          rootImage = fileAzureUrl
        } catch (error) {
          console.log('error main image',error)
        }
      }

      if(c.options){
        const newOptions = await Promise.all(c.options.map(async (option)=>{
          let optionImage = option.image
          if (option.imageType === 'file') {
            try {
              const ext = extractExtensionFromBase64(c.image)
              const filename = generateRandomFilename(ext)
              const file = await blobUrlToFile(c.image, filename)
              const fileAzureUrl = await uploadToAzure({
                file:file,
                direcatoryPath:'',
                containerName:'formbuilder',
                type: "formbuilder"
              })
              optionImage = fileAzureUrl
            } catch (error) {
              console.log('error option',error)
            }
          }

          return {
            ...option,
            image: optionImage
          }
        }))

        rootOptions = newOptions
      }

      return {
        ...c,
        image:rootImage,
        ...(rootOptions ? {
          options:rootOptions
        }:{})
      };
    }));
    return JSON.stringify(newFormFields || [])
  }

  uploadEngraving({
    loader,
    productId,
  }){
    const constructDesignFiles = files => files.map((f, i)=>({
      id:i+1,
      name:f.thumbnail ?? '',
      content:f.thumbnailUrl ?? '',
      type:f.thumbnailFiletype ?? '',
    }))
    
    const designImageFilesLR:ModelService.ImageFileType[] =  constructDesignFiles(loader.selectedDesignImagesLR)
    const designImageFilesHR:ModelService.ImageFileType[] =  constructDesignFiles(loader.selectedDesignImagesHR)
    const payload = {
      productId,
      designImageFilesLR: designImageFilesLR,
      designImageFilesHR: designImageFilesHR,
      abortController: this.abortController,
      type: loader.loadingData.designType,
    }

    const createEngraving =  ModelService.createEngravingDesign(payload)
    createEngraving.then(()=>{
      console.log('CREATE ENGRAVING/TEXTURE', true)
    }).catch((err)=>{
      console.error('ERROR CREATE ENGRAVING/TEXTURE', err)
    })
  }

  @Watch('workflowModel.finished', { immediate: true })
  onStepFinished(): void {
    if (this.workflowModel.finished) {
      const loader = this.$refs.meshLoader as MeshLoader;
      const configFields = localStorage.getItem('fields');

      if(configFields) loader.loadingData.title = `${loader.loadingData.title} phase1`

      if(loader.selectedDesignImagesLR.length > 0  || loader.selectedDesignImagesHR.length > 0){
        if(loader.selectedDesignImagesLR.length !== loader.selectedDesignImagesHR.length){
          ElMessageBox.alert(
          (this as any).$t('error.vuelidate.designNotmatch'), 
          'Warning', {
            type: 'warning', 
            confirmButtonText: 'OK',
          })
          return
        }
      }

      this.fullscreenLoading = true;
      setTimeout(async() => {
        if (loader) {
          const result = loader.getResult();
          if (result) {
            ModelService.uploadNewProduct({
              modelId: this.templateId,
              productName: loader.loadingData.title,
              category: loader.loadingData.category,
              templates: loader.selectedProductImages,
              template: loader.selectedInput,
              treeData: loader.selectedInput.treeData,
              description: loader.loadingData.description,
              productType:loader.loadingData.productType,
              basePrice: 100,
            }).then(async (result) => {
              if (result.modelId && result.productId) {
              
               this.uploadEngraving({
                loader,
                productId:result.productId,
               })
                
                const newConfigFields = await this.prepareFormBuilderPayloads(JSON.parse(configFields ?? '[]'))
                FormBuilderService.createProductFormBuilder({
                  productId: result.productId,
                  formFields: newConfigFields || '[]',
                })
                .then(() => {
                  localStorage.removeItem('fields');
                  const meshLoaderRef = this.$refs.meshLoader as MeshLoader;
                  if (meshLoaderRef) meshLoaderRef.resetFormBuilderState()
                  this.$router.replace(`/template-upload?id=${result}`);
                  setTimeout(() => {
                    this.$router.go(-1);
                  }, 50);
                })
                .catch((err) => {
                  console.error('err', err);
                });

                // Store video metadata on Azure Tables Storage ... 
                await this.handleStoreVideoMetadataToAzure(
                  result?.productId.toString(), //convert number to string 
                  loader.selectedInput
                )
                
              } else {
                //TODO throw Exception
              }
            });
          }
        }
      }, 100);
    }
  }

  async handleStoreVideoMetadataToAzure(productId: string, payload: MeshTreeData): Promise<void> {
    if(!productId.length || !payload) return;
    
    // destructor video and video thumbnail metadata 
    const { 
      video, 
      videoFiletype, 
      videoUrl, 
      videoThumbnail,
      videoThumbnailFiletype,
      videoThumbnailUrl,
    } = payload
    
    const videoData: TableField[] = []

    if(payload.videoUrl?.length) {
      videoData.push({
        productId,
        filename: video || '',
        fileType: videoFiletype || FileTypeVideo.WEBM,
        fileUrl: videoUrl || ''
      })
    }

    if(payload.videoThumbnailUrl?.length) {
      videoData.push({
        productId,
        filename: videoThumbnail || '',
        fileType: videoThumbnailFiletype || FileTypeImage.PNG,
        fileUrl: videoThumbnailUrl || ''
      })
    }

    if(!videoData.length) return;

    await saveFileMetadataToAzureTables(videoData)
  }

  @Watch('workflowModel.active', { immediate: true })
  onWorkflowModel(newValue: number, oldValue: number): void {
    const fields = localStorage.getItem('fields')
    if(oldValue === 0 && newValue === 1 && fields) this.showConfirmUseProductConfig()
  }

  showConfirmUseProductConfig(): void {
    const meshLoaderRef = this.$refs.meshLoader as MeshLoader;
    // const confirmYes = makeWordToCapitalized((this as any).$t('components.mesh-loader.yes'), 'yes')
    // const confirmNo = makeWordToCapitalized((this as any).$t('components.mesh-loader.yes'), 'no')

    ElMessageBox.confirm(
      (this as any).$t('components.mesh-loader.productConfigConfirmMessage'),
      'info',
      {
        confirmButtonText: (this as any).$t('components.mesh-loader.yes'),
        cancelButtonText: (this as any).$t('components.mesh-loader.no'),
        type: 'warning',
        closeOnClickModal: false,
        showClose: false
      }
    )
      .then((confirm) => {
        // if confirm next to use product confirm ... 
        this.isProductConfig = true
        if(meshLoaderRef) meshLoaderRef.updateProductConfigButtonText(true)
      })
      .catch(() => {
        localStorage.removeItem('fields');
        this.isProductConfig = false
        if(meshLoaderRef) meshLoaderRef.updateProductConfigButtonText(false)
      });
  }
}
</script>

<style lang="scss" scoped>
.el-main::v-deep {
  display: flex;
  flex-direction: column;
}
.level:not(:last-child) {
  margin-bottom: unset;
}

.level,
.workflow {
  z-index: 1;
}
</style>
