import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24ff3582"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data"]
const _hoisted_2 = { class: "webGlView" }
const _hoisted_3 = {
  "element-loading-background": "transparent",
  style: {"z-index":"1"}
}
const _hoisted_4 = {
  key: 0,
  class: "hierarchy"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrientationGizmo = _resolveComponent("OrientationGizmo")!
  const _component_Camera = _resolveComponent("Camera")!
  const _component_AmbientLight = _resolveComponent("AmbientLight")!
  const _component_PointLight = _resolveComponent("PointLight")!
  const _component_Scene = _resolveComponent("Scene")!
  const _component_RenderPass = _resolveComponent("RenderPass")!
  const _component_EffectComposer = _resolveComponent("EffectComposer")!
  const _component_Renderer = _resolveComponent("Renderer")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_ToggleSidebar = _resolveComponent("ToggleSidebar")!
  const _component_EmbossingSidebar = _resolveComponent("EmbossingSidebar")!
  const _component_PreviewRendererImg = _resolveComponent("PreviewRendererImg")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", { data: _ctx.modelValue }, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createVNode(_component_el_main, {
          class: "fillHorizontal",
          id: "main"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(_component_OrientationGizmo, { ref: "orientationGizmo" }, null, 512), [
              [_vShow, false]
            ]),
            (_ctx.isActive)
              ? (_openBlock(), _createBlock(_component_Renderer, {
                  key: 0,
                  ref: "troisRenderer",
                  antialias: "",
                  "orbit-ctrl": {
              enableDamping: false,
              dampingFactor: 0.05,
              enablePan: true,
            },
                  resize: true,
                  alpha: true,
                  xr: true,
                  preserveDrawingBuffer: true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Camera, { position: _ctx.renderCameraPos }, null, 8, ["position"]),
                    _createVNode(_component_Scene, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_AmbientLight, { color: "#808080" }),
                        _createVNode(_component_PointLight, {
                          position: _ctx.pointLightPos,
                          intensity: 0.55
                        }, null, 8, ["position"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_EffectComposer, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_RenderPass)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 512))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])), [
        [_directive_loading, _ctx.loadingCircle]
      ]),
      (_ctx.canEmbossing)
        ? (_openBlock(), _createBlock(_component_ToggleSidebar, {
            key: 0,
            modelValue: _ctx.showSidebar,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showSidebar) = $event)),
            useLeftSidebar: false
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.canEmbossing && _ctx.showSidebar)
        ? (_openBlock(), _createBlock(_component_EmbossingSidebar, {
            key: 1,
            modelValue: _ctx.selectedEmbossing,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedEmbossing) = $event)),
            selectObject: _ctx.selectObject,
            selectionList: _ctx.selectionList,
            historyList: _ctx.modelValue.historyList
          }, null, 8, ["modelValue", "selectObject", "selectionList", "historyList"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.canPartSelect)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_el_scrollbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_space, null, {
                default: _withCtx(() => [
                  _createVNode(_component_PreviewRendererImg, {
                    meshes: _ctx.modelValue.treeData,
                    imgSize: "5",
                    offset: 1.5,
                    canRemove: true,
                    canSelect: true,
                    isSelected: true,
                    canTakeSnapshot: _ctx.canTakeSnapshot,
                    onSelectItem: _ctx.selectPart,
                    onTakeSnapshot: _ctx.takeSnapshot
                  }, null, 8, ["meshes", "canTakeSnapshot", "onSelectItem", "onTakeSnapshot"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}