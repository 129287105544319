<template>
  <el-container>
    <el-aside
      class="withFooterAndHeader"
      v-if="uploadProgress === UploadProgress.Select"
      width="14rem"
    >
      <el-scrollbar>
        <el-form
          ref="dataForm"
          :model="loadingData"
          label-position="top"
          status-icon
        >
          <el-form-item
            v-if="referenceData && referenceData.length > 0"
            prop="referenceCategory"
            :label="$t('components.mesh-loader.reference-category')"
          >
            <el-select v-model="loadingData.referenceCategory">
              <el-option
                v-for="category in Object.values(UploadCategory)"
                :key="category"
                :value="category"
                :label="$t(`enum.upload-category.${category}`)"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="referenceData && referenceData.length > 0"
            prop="reference"
            :label="$t('components.mesh-loader.reference')"
          >
            <el-select v-model="loadingData.referenceId">
              <el-option
                v-for="reference in categoryReferences"
                :key="reference"
                :value="reference.uniqueKey"
                :label="reference.name"
              />
            </el-select>
          </el-form-item>

          <!-- Category Form -->
          <el-form-item
            prop="category"
            :label="$t('components.mesh-loader.category')"
          >
            <el-select v-model="loadingData.category">
              <el-option
                v-for="category in Object.values(UploadCategory)"
                :key="category"
                :value="category"
                :label="$t(`enum.upload-category.${category}`)"
              />
            </el-select>
          </el-form-item>

          <!-- ProductType Form -->
          <el-form-item
            v-if="isProductCategory"
            prop="productType"
            :label="$t('components.mesh-loader.producttype')"
          >
            <el-select
              v-model="loadingData.productType"
              @change="logProductType"
              :disabled="isFetchingProductTypeList"
            >
              <el-option
                v-for="productType in productTypeList"
                :key="productType"
                :value="productType"
                :label="$t(`enum.product-type.${productType}`)"
              />
            </el-select>
          </el-form-item>

          <!-- Mesh Name -->
          <el-form-item
            prop="title"
            :label="$t('components.mesh-loader.title')"
            :rules="{
              required: true,
              message: $t('error.vuelidate.required'),
              trigger: 'blur',
            }"
          >
            <el-input v-model="loadingData.title" />
          </el-form-item>

          <!-- Mesh Description -->
          <el-form-item
            prop="description"
            :label="$t('components.mesh-loader.description')"
            :rules="{
              required: true,
              message: $t('error.vuelidate.required'),
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="loadingData.description"
              :rows="2"
              type="textarea"
            />
          </el-form-item>

          <!-- Upload 3D Object  -->
          <el-form-item
            prop="url"
            :label="$t('components.mesh-loader.mesh')"
            :rules="{
              required: true,
              message: $t('error.vuelidate.required'),
              trigger: 'blur',
            }"
          >
            <el-upload
              multiple
              v-model:file-list="meshFiles"
              action="#"
              drag
              list-type="picture-card"
              :before-upload="beforeUpload"
              :http-request="(res)=>uploadFile(res, ImageType.object3D)"
              accept=".stl,.obj,.fbx,.mtl"
            >
              <template #default>
                <div>
                  <font-awesome-icon icon="upload" class="primary big" />
                  <br />
                  {{ $t('components.mesh-loader.upload') }}
                </div>
              </template>
              <template #file="{ file }">
                <PreviewRenderer
                  :meshes="[
                    {
                      filetype: getFileType(file.name),
                      filename: file.name,
                      url: file.url,
                      raw: file.raw,
                    },
                  ]"
                  :canRemove="true"
                  :canSelect="false"
                  v-on:removeItem="handleRemove(file, ImageType.object3D)"
                />
              </template>
            </el-upload>
          </el-form-item>
          
          <!-- Upload Product Image  -->
          <el-form-item
            :label="$t('components.mesh-loader.thumbnail')"
            :rules="{
              required: true,
              message: $t('error.vuelidate.required'),
              trigger: 'blur',
            }"
          >
            <el-upload
              multiple
              v-model:file-list="productImageFiles"
              action="#"
              drag
              list-type="picture-card"
              :before-upload="beforeImageUpload"
              :http-request="(res)=>uploadFile(res, ImageType.product)"
              accept=".jpg,.png,"
            >
              <template #default>
                <div>
                  <font-awesome-icon icon="upload" class="primary big" />
                  <br />
                  {{ $t('components.mesh-loader.upload-image') }}
                </div>
              </template>
              <template #file="{ file }">
                <PreviewRendererImg
                  :meshes="[
                    {
                      thumbnailFiletype: getImageFileType(file.name),
                      thumbnail: file.name,
                      thumbnailUrl: file.url,
                    },
                  ]"
                  :canSelect="false"
                  :canRemove="true"
                  v-on:removeItem="handleRemove(file, ImageType.product)"
                />
              </template>
            </el-upload>
          </el-form-item>

           <!-- Design or Texture Dropdown -->
           <el-form-item
            prop="designType"
            :label="$t(`components.mesh-loader.designtype`)"
          >
            <el-select
              v-model="loadingData.designType"
              @change="onDesignTypeChange"
            >
              <el-option
                v-for="designType in designTypeList"
                :key="designType"
                :value="designType"
                :label="$t(`enum.design-type.${designType}`)"
              />
            </el-select>
          </el-form-item>

          <!-- Upload Design/Texture Product Low Resolution -->
          <p class="text-info">
            <span>{{$t('components.mesh-loader.uploadDesigninfo')}}</span> 
            <font-awesome-icon 
              v-if="designImageFilesHR.length > 0 || designImageFilesLR.length > 0" 
              icon="edit" 
              class="text-dark fs-5 pointer ml-1" 
              @click="this.designFilesDialog.show();"
            />
          </p>

          <el-form-item
            :label="$t(`components.mesh-loader.${loadingData.designType.toLowerCase()}`)+' (LR)'"
          >
            <el-upload
              multiple
              v-model:file-list="designImageFilesLR"
              action="#"
              drag
              list-type="picture-card"
              :before-upload="beforeImageUpload"
              :http-request="(res)=>uploadFile(res, ImageType.designLR)"
              accept=".jpg,.png,"
            >
              <template #default>
                <div>
                  <font-awesome-icon icon="upload" class="primary big" />
                  <br />
                  {{ $t(`components.mesh-loader.upload-${loadingData.designType.toLowerCase()}-image`) }}
                </div>
              </template>
              <template #file="{ file }">
                <PreviewRendererImg
                  :meshes="[
                    {
                      thumbnailFiletype: getImageFileType(file.name),
                      thumbnail: file.name,
                      thumbnailUrl: file.url,
                    },
                  ]"
                  :canSelect="false"
                  :canRemove="true"
                  v-on:removeItem="handleRemove(file, ImageType.designLR)"
                />
              </template>
            </el-upload>
          </el-form-item>
          <!-- Upload Design/Texture Product High Resolution -->
          <el-form-item
            :label="$t(`components.mesh-loader.${loadingData.designType.toLowerCase()}`)+' (HR)'"
          >
            <el-upload
              multiple
              v-model:file-list="designImageFilesHR"
              action="#"
              drag
              list-type="picture-card"
              :before-upload="beforeImageUpload"
              :http-request="(res)=>uploadFile(res, ImageType.designHR)"
              accept=".jpg,.png,"
            >
              <template #default>
                <div>
                  <font-awesome-icon icon="upload" class="primary big" />
                  <br />
                  {{ $t(`components.mesh-loader.upload-${loadingData.designType.toLowerCase()}-image`) }}
                </div>
              </template>
              <template #file="{ file }">
                <PreviewRendererImg
                  :meshes="[
                    {
                      thumbnailFiletype: getImageFileType(file.name),
                      thumbnail: file.name,
                      thumbnailUrl: file.url,
                    },
                  ]"
                  :canSelect="false"
                  :canRemove="true"
                  v-on:removeItem="handleRemove(file, ImageType.designHR)"
                />
              </template>
            </el-upload>
          </el-form-item>

          <!-- Upload Product Video  -->
          <el-form-item
            prop="videoUrl"
            :label="$t('components.mesh-loader.video')"
            :rules="{
              required: true,
              message: $t('error.vuelidate.required'),
              trigger: 'blur',
            }"
          >
            <el-upload
              multiple
              v-model:file-list="productVideoFiles"
              action="#"
              drag
              list-type="picture-card"
              :before-upload="beforeVideoUpload"
              :http-request="(res) => uploadFile(res, ImageType.video)"
              accept=".mp4,.webm,.mov,.mkv,.avi"
            >
              <template #default>
                <div>
                  <font-awesome-icon icon="upload" class="primary big" />
                  <br />
                  {{ $t('components.mesh-loader.upload-video') }}
                </div>
              </template>
              <template #file="{ file }">
                <PreviewRendererVideo 
                  :meshes="[
                      {
                        videoFiletype: getVideoFileType(file.name),
                        video: selectedInput.video,
                        videoUrl: selectedInput.videoUrl,
                        videoThumbnailFiletype: selectedInput.videoThumbnailFiletype,
                        videoThumbnail: selectedInput.videoThumbnail,
                        videoThumbnailUrl: selectedInput.videoThumbnailUrl,
                      },
                  ]"
                  :canRemove="false"
                  :canPlay="true"
                  :isLoading="uploadVideoLoading"
                  v-on:removeItem="handleRemove(file, ImageType.video)"
                />
              </template>
            </el-upload>
          </el-form-item>

          <el-form-item prop="configuration" :label="'Configuration'">
            <button
              type="button"
              class="btn btn-primary"
              @click="setShowFormBuilder()"
            >
              {{ productConfigButtonText }}
            </button>

            <!-- <button type="button" @click="handleStoreData()">Test Store data</button> -->
          </el-form-item>

        </el-form>
      </el-scrollbar>
    </el-aside>
    <el-aside
      class="withFooterAndHeader"
      v-if="uploadProgress === UploadProgress.Pivot"
      width="14rem"
    >
      <el-scrollbar>
        <el-steps direction="vertical" :active="activeBoneIndex" :key="boneKey">
          <draggable
            v-model="boneData"
            handle=".el-step__icon"
            item-key="id"
            @end="dragDone"
          >
            <template #item="{ element, index }">
              <el-step @click="activeBoneIndex = index">
                <template #title>
                  <span class="media">
                    <el-input
                      class="media-content"
                      v-model="element.name"
                      :placeholder="$t('components.mesh-loader.boneName')"
                    />
                    <span
                      class="action media-right"
                      @click="removeBone(index)"
                      v-if="boneData.length > 2"
                    >
                      <font-awesome-icon icon="trash" />
                    </span>
                  </span>
                </template>
              </el-step>
            </template>
          </draggable>
        </el-steps>
        <div class="flexRemaining"></div>
        <div class="addButton">
          <el-button type="primary" circle @click="addBone(null)">
            <font-awesome-icon icon="plus" />
          </el-button>
        </div>
      </el-scrollbar>
    </el-aside>
    <el-main>
      <MeshEditor
        ref="meshEditor"
        v-model="selectedInput"
        :canTogglePivotMode="false"
        :canSelect="canSelect"
        :canToggleBones="false"
        :canUndo="uploadProgress !== UploadProgress.Select"
        :canDeform="false"
        :canCurve="false"
        :canResolution="false"
        :canPartSelect="true"
        :canToggleOpacity="canToggleOpacity"
        :activateAlineToFloor="true"
        :activateBones="uploadProgress === UploadProgress.Pivot"
        :activeEditorMode="activeEditorMode"
        :activeModifyType="
          uploadProgress === UploadProgress.Orientation
            ? ModifyType.rotate
            : ModifyType.translate
        "
        :resetOnPivotChanged="true"
        :fitCameraToScene="true"
        :defaultOrientationAxis="
          uploadProgress === UploadProgress.Orientation
            ? OrientationAxis.y
            : OrientationAxis.none
        "
        :canTakeSnapshot="canTakeSnapshot"
        v-on:takeSnapshot="takeSnapshot"
      />
    </el-main>
  </el-container>

  <div
    class="modal fade"
    id="formbuilder-dialog"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content" id="info-content">
        <div class="modal-body p-0" id="info-body">
          <iframe
            src="/formbuilder/index.html"
            style="width: 100%; height: 100vh; border: none"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="design-files-dialog"
    tabindex="-1"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content" id="info-content">
        <div class="modal-body p-3" id="info-body">
          <p>Low Resolution</p>
          <div class="row my-2" v-for="(file, index) in designImageFilesLR" :key="file.uid">
            <div class="col-lg-1">
              <span>{{index + 1}}</span>
            </div>
            <div class="col-lg-2">
              <img class="img-fluid" :src="file.url"/>
            </div>
            <div class="col-lg-6">
              <label :for="`filename-${index}`">File Name</label>
              <input type="text" :id="`filename-${index}`" class="form-control" v-model="file.name">
            </div>
          </div>
          <hr class="my-3">
          <p>High Resolution</p>
          <div class="row my-2" v-for="(file, index) in designImageFilesHR" :key="file.uid">
            <div class="col-lg-1">
              <span>{{index + 1}}</span>
            </div>
            <div class="col-lg-2">
              <img class="img-fluid" :src="file.url"/>
            </div>
            <div class="col-lg-6">
              <label :for="`filename-${index}`">File Name</label>
              <input type="text" :id="`filename-${index}`" class="form-control" v-model="file.name">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" @click="()=>{
            const rervertFilesLR = designImageFilesLR.map((file:any)=>({...file, name:file.raw.name}))
            const rervertFilesHR = designImageFilesHR.map((file:any)=>({...file, name:file.raw.name}))
            designImageFilesLR = rervertFilesLR
            designImageFilesHR = rervertFilesHR
            designFilesDialog.hide()
          }">Cancel</button>
          <button class="btn btn-primary" @click="()=>{
            const updateFilename = (files, stateFiles)=>{
              return files.map((file)=>{
              const stateFile = stateFiles.find(f=>f.uid === file.uid) as any
              const newName = stateFile.name
              const fileExtension = stateFile?.raw.type.split('/')[1]
              const hasExtension = newName.split('.')[1]
              const newThumbnail = hasExtension ? `${newName.split('.')[0]}.${fileExtension}` : `${newName}.${fileExtension}`
              return {
                ...file,
                thumbnail:newThumbnail
              }
            })
            }
            selectedDesignImagesLR = updateFilename(selectedDesignImagesLR, designImageFilesLR)
            selectedDesignImagesHR = updateFilename(selectedDesignImagesHR, designImageFilesHR)
            designFilesDialog.hide()
          }">Apply</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { ElForm, ElMessage, ElMessageBox } from 'element-plus';
import {
  UploadFile,
  UploadStatus,
} from 'element-plus/es/components/upload/src/upload';
import { ObjModel, StlModel } from '@/utils/three/importExport';
import MeshEditor from '@/components/three/MeshEditor.vue';
import { MeshTreeData } from '@/types/ui/MeshTreeData';
import { Prop, Watch } from 'vue-property-decorator';
import {
  DesignType,
  FileType3D,
  FileTypeImage,
  FileTypeVideo,
  getFileType,
  getImageFileType,
  getVideoFileType,
  isValidFileType,
  isValidImageFileType,
  isValidVideoFileType,
  ProductType,
  UploadCategory,
  UploadProgress,
} from '@/types/enum/upload';
import { ModifyType, OrientationAxis } from '@/types/enum/three';
import draggable from 'vuedraggable';
import * as THREE from 'three';
import { EditorModeState } from '@/types/enum/editor';
import PreviewRenderer from '@/components/three/PreviewRenderer.vue';
import PreviewRendererImg from '@/components/three/PreviewRendererImg.vue';
import PreviewRendererVideo from '@/components/three/PreviewRendererVideo.vue';
import * as TemplateService from '@/services/api/templateService';
import { MeshExportData } from '@/services/api/modelService';
import { DefaultUndoRedo } from '@/types/ui/HistoryList';
import { deleteFileFromAzure, deleteFileMetadataToAzureByProudctId, fileContentToBase64, generateVideoThumbnail, getFileMetadataToAzureByProudctId, ProductVideoFiles, saveFileMetadataToAzureTables, TableField, uploadToAzure } from '@/utils/file';
import { Modal } from 'bootstrap';
import { Buffer } from 'buffer'; // Impor Buffer polyfill
import { cloneDeep } from '@apollo/client/utilities';
import { FileData } from '@/types/enum/file';
import { GetAllDistinctProductTypes } from '@/services/api/productService';

// Set Buffer as global for the browser environment
(window as any).Buffer = Buffer;


interface UploadData {
  name: string;
  url: string;
  uid: number;
  status?: UploadStatus;
}

interface LoadingData {
  referenceCategory: UploadCategory;
  referenceId: string;
  category: UploadCategory;
  productType: ProductType;
  designType: DesignType;
  title: string;
  description: string;
  fileType: FileType3D;
  url: string;
  base64: string;
}

interface ResultData {
  category: UploadCategory;
  title: string;
  mesh: THREE.Object3D;
  bones: THREE.Bone[];
  referenceId: string;
}

export type MeshTreeDataWithUid = MeshTreeData & { uid?: number };
export enum ImageType {
  "object3D"="object3D",
  "product"="product",
  "designLR"="designLR",
  "designHR"="designHR",
  "video"="video",
}

@Options({
  components: {
    PreviewRenderer,
    MeshEditor,
    ObjModel,
    StlModel,
    draggable,
    PreviewRendererImg,
    PreviewRendererVideo,
  },
  emits: ['update:uploadProgress'],
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class MeshLoader extends Vue implements DefaultUndoRedo {
  @Prop({ default: UploadProgress.Select }) uploadProgress!: UploadProgress;
  @Prop({ default: null }) templateId!: number | null;
  @Prop({ default: 0 }) activeStep!: number;
  @Prop({ default: false }) canSelect!: boolean;
  @Prop({ default: false }) canTakeSnapshot!: boolean;
  @Prop({ default: false }) canToggleOpacity!: boolean;
  @Prop({ default: 'other' }) directoryPath!: string;
  @Prop({ default: false }) isProductConfig!: boolean;
  selected = -1;
  ImageType = ImageType;

  // note: state for mesh 3d file (its for payload ModelService.uploadNewProduct).
  selectedInput: MeshTreeData = new MeshTreeData(null, this);
  // note: state for controlled el upload mesh 3d, keeping ui when clicking prev step
  meshFiles: FileData[] = [];

  // note: state for product image thumbnails refers to selected input format. Separate it from selectedInput state since it needs to be multiple  (it's for payload ModelService.uploadNewProduct).
  selectedProductImages: MeshTreeDataWithUid[] = [];
  // note: state for controlled el upload product image, keeping ui when clicking prev step
  productImageFiles: FileData[] = [];
  productVideoFiles: ProductVideoFiles[] = [];

  
  // note: state for product image thumbnails refers to selected input format. Separate it from selectedInput state since it needs to be multiple  (it's for payload ModelService.uploadNewProduct).
  selectedDesignImagesLR: MeshTreeDataWithUid[] = [];
  // note: state for controlled el upload product image, keeping ui when clicking prev step
  designImageFilesLR: FileData[] = [];

  selectedDesignImagesHR: MeshTreeDataWithUid[] = [];
  designImageFilesHR: FileData[] = [];

  UploadProgress = UploadProgress;
  ModifyType = ModifyType;

  isFetchingProductTypeList = false
  productTypeList = []
  designTypeList: string[] = []

  loadingData: LoadingData = {
    referenceCategory: UploadCategory.ReferenceDummy,
    referenceId: '',
    category: UploadCategory.Product,
    productType: ProductType.fence, // Add this line
    designType: DesignType.design,
    title: '',
    description: '',
    fileType: FileType3D.NONE,
    url: '',
    base64: '',
  };

  boneData: THREE.Bone[] = [];
  activeBoneIndex = 0;
  boneKey = 0;

  activeEditorMode: EditorModeState = EditorModeState.readonly;

  referenceData: MeshExportData[] = [];

  UploadCategory = UploadCategory;
  ProductType = ProductType;
  FileType = FileType3D;
  previousUploadProgress = UploadProgress.Select;
  OrientationAxis = OrientationAxis;
  getFileType = getFileType;
  getImageFileType = getImageFileType;
  getVideoFileType = getVideoFileType;
  showFormBuilder = false;
  private formbuilderDialog: any = null;
  private designFilesDialog: any = null;
  uploadVideoLoading = false
  private productConfigButtonText = 'Create Product Configs'

  // Watch untuk mendeteksi perubahan state isLoading
  @Watch('selectedInput.video')
  onIsLoadingChanged(newValue: MeshTreeData) {
    console.log('selectedInput.video detected change:', newValue);
  }

  mounted(): void {
    if (this.templateId) {
      TemplateService.getMeshData(this.templateId).then(
        (result) => (this.referenceData = result)
      );
    }

    this.isFetchingProductTypeList = true
    GetAllDistinctProductTypes().then(res=>{
      if(res.allDistinctProductTypes) {
        const productTypeList = res.allDistinctProductTypes.map(p=>p.name) 
        this.productTypeList = productTypeList
      }
      this.isFetchingProductTypeList = false
    }).catch((err)=>{
      this.isFetchingProductTypeList = false
      console.error('[ERROR GET PRODUCT TYPES]: ',err)
    })

    this.designTypeList = Object.values(DesignType)
    this.addBone((this as any).$t('components.mesh-loader.pivotBone'));
    this.addBone((this as any).$t('components.mesh-loader.secondBone'));
    this.$nextTick(() => {
      this.activeBoneIndex = 0;
    });

    const modalDialogOptions = {};
    this.formbuilderDialog = new Modal(
      '#formbuilder-dialog',
      modalDialogOptions
    );
    this.designFilesDialog = new Modal(
      '#design-files-dialog',
      {
        backdrop:'static'
      }
    );

    const fields = localStorage.getItem('fields')
    fields 
      ? this.productConfigButtonText = 'Edit Product Configs' 
      : this.productConfigButtonText = 'Create Product Configs'

      window.addEventListener('message', (e)=>{
          if(e.data === 'closeModal'){
            this.formbuilderDialog.hide()
          }
      }, false);

  }

  async handleStoreData(): Promise<void> {
    console.log('handleStoreData')

    // const fileData = [
    //   {
    //     productId: '227',
    //     filename: 'Test file3',
    //     fileType: FileTypeImage.PNG,
    //     fileUrl: 'https://test.com'
    //   },
    //   {
    //     productId: '227',
    //     filename: 'Test file4',
    //     fileType: FileTypeImage.PNG,
    //     fileUrl: 'https://test.com'
    //   }
    // ]


    // await saveFileMetadataToAzureTables(fileData)
    // await getFileMetadataToAzureByProudctId('227')
    // await deleteFileMetadataToAzureByProudctId('227')
  }

  resetFormBuilderState(){
    const formBuilderIframe = document.querySelector('#formbuilder-dialog iframe') as HTMLIFrameElement | null;
    if(formBuilderIframe){
      formBuilderIframe.contentWindow?.postMessage('RESET_STATE',  "*")
    }
  }

  updateProductConfigButtonText(value: boolean): void {
    if(!value) this.resetFormBuilderState()
    this.productConfigButtonText = value 
      ? 'Edit Product Configs' 
      : 'Create Product Configs'
  }

  get categoryReferences(): MeshExportData[] {
    const references = this.referenceData.filter(
      (item) => item.category === this.loadingData.referenceCategory
    );
    this.loadingData.referenceId = '';
    if (references.length > 0)
      this.loadingData.referenceId = references[0].uniqueKey;
    return references;
  }

  getResult(): ResultData | null {
    if (this.selectedInput.treeData.length > 0) {
      const treeData = this.selectedInput.treeData[0];
      return {
        category: this.loadingData.category,
        title: this.loadingData.title,
        mesh: treeData.mesh,
        bones: treeData.bones,
        referenceId: this.loadingData.referenceId,
      };
    }
    return null;
  }

  get isProductCategory(): boolean {
    return this.loadingData.category === UploadCategory.Product;
  }

  @Watch('templateId', { immediate: true })
  onTemplateIdChanged(): void {
    this.selectedInput.modelId = this.templateId;
    this.selectedInput.historyList.modelId = this.templateId;
    this.selectedInput.historyList.defaultUndoRedo = this;
  }

  @Watch('selectedInput.lastUpdateVisibility')
  async onSelectedInputVisibilityChanged(): Promise<void> {
    if (this.activeEditorMode === EditorModeState.bone) {
      if (this.selectedInput && this.selectedInput.treeData.length > 0) {
        this.activeBoneIndex = this.selectedInput.treeData[0].activeBoneIndex;
      }
    }
  }

  @Watch('loadingData.referenceId', { immediate: true })
  onReferenceIdChanged(): void {
    if (this.templateId && this.loadingData.referenceId) {
      const referenceItem = this.referenceData.find(
        (item) => item.uniqueKey === this.loadingData.referenceId
      );
      if (referenceItem) {
        this.boneData.length = 0;
        for (const boneData of referenceItem.bones) {
          const bone = this.addBone(boneData.name);
          bone.position.copy(boneData.position.clone());
        }
      }
    }
  }

  @Watch('loadingData.title', { immediate: true })
  onTitleChanged(): void {
    console.log('loadingData', this.loadingData.title)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async dragDone(event: any): Promise<void> {
    this.boneKey += 1;
    if (this.activeBoneIndex === event.oldIndex) {
      this.activeBoneIndex = event.newIndex;
    } else if (
      this.activeBoneIndex < event.oldIndex &&
      this.activeBoneIndex >= event.newIndex
    ) {
      this.activeBoneIndex++;
    } else if (
      this.activeBoneIndex > event.oldIndex &&
      this.activeBoneIndex <= event.newIndex
    ) {
      this.activeBoneIndex--;
    }
    this.selectedInput.updateVisibility();
  }

  @Watch('activeBoneIndex')
  onActiveBoneIndexChanged(): void {
    if (this.selectedInput && this.selectedInput.treeData.length > 0) {
      this.selectedInput.treeData[0].activeBoneIndex = this.activeBoneIndex;
      this.selectedInput.updateVisibility();
    }
  }

  addBone(name: string | null = null): THREE.Bone {
    const defaultBoneDistance = 100;
    const bone = new THREE.Bone();
    bone.name = name ? name : (this.boneData.length + 1).toString();
    if (this.boneData.length === 0) {
      bone.position.set(0, 0, 0);
    } else {
      const to = this.boneData[this.boneData.length - 1].position.clone();
      const from =
        this.boneData.length === 1
          ? new THREE.Vector3(0, -1, 0)
          : this.boneData[this.boneData.length - 2].position.clone();
      const direction = to
        .clone()
        .sub(from)
        .normalize()
        .setLength(defaultBoneDistance);
      bone.position.copy(to.clone().add(direction));
    }
    this.boneData.push(bone);
    this.selectedInput.updateVisibility();
    this.$nextTick(() => {
      this.activeBoneIndex = this.boneData.length - 1;
    });
    return bone;
  }

  removeBone(index: number): void {
    ElMessageBox.confirm(
      (this as any).$t('confirm.delete.message'),
      (this as any).$t('confirm.delete.title'),
      {
        confirmButtonText: (this as any).$t('confirm.delete.ok'),
        cancelButtonText: (this as any).$t('confirm.delete.cancel'),
        type: 'warning',
      }
    ).then(() => {
      this.boneData.splice(index, 1);
      this.selectedInput.updateVisibility();
    });
  }

  isFileType(filename: string, filetype: FileType3D): boolean {
    return getFileType(filename) === filetype;
  }

  @Watch('uploadProgress', { immediate: true })
  onUploadProgressChanged(): void {
    if (
      this.previousUploadProgress === UploadProgress.Select &&
      this.uploadProgress !== this.previousUploadProgress
    ) {
      this.previousUploadProgress = this.uploadProgress;
      const dataForm = this.$refs.dataForm as typeof ElForm;
      dataForm?.validate(async (valid) => {
        if (!valid) {
          this.$emit('update:uploadProgress', UploadProgress.Select);
          this.previousUploadProgress = UploadProgress.Select;
        }
      });
    }

    switch (this.uploadProgress) {
      case UploadProgress.Select:
        this.activeEditorMode = EditorModeState.readonly;
        break;
      case UploadProgress.Orientation:
        this.activeEditorMode = EditorModeState.orientation;
        break;
      case UploadProgress.Pivot:
        this.activeEditorMode = EditorModeState.bone;
        break;
    }
  }

  takeSnapshot(): void {
    const meshEditor: any = this.$refs.meshEditor;
    const img = new Image();

    if (meshEditor.troisRenderer.scene && meshEditor.troisRenderer.camera) {
      meshEditor.troisRenderer.three.renderer.render(
        meshEditor.troisRenderer.scene,
        meshEditor.troisRenderer.camera
      );
    }

    img.src = meshEditor.troisRenderer.three.renderer.domElement.toDataURL();

    meshEditor.troisRenderer.three.renderer.clear();

    for (const item of this.selectedInput.treeData) {
      if (item.isSelected) {
        item.thumbnailUrl = img.src;
        item.thumbnail = item.name;
        item.thumbnailFiletype = FileTypeImage.JPG;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async uploadFile(res: any, imageType:ImageType): Promise<boolean> {
    if(imageType === ImageType.object3D){
      const url = URL.createObjectURL(res.file);
      const data = { name: res.file.name, url: url, uid: res.file.uid };
      setTimeout(() => {
        this.addMesh(data);
        this.handleSelect(data);
      }, 500);
    }
    if(imageType === ImageType.product){
      fileContentToBase64(res.file, (encodedString) => {
        this.selectedInput.thumbnail = res.file.name;
        (this.selectedInput.thumbnailFiletype = getImageFileType(res.file.name)),
          (this.selectedInput.thumbnailUrl = encodedString);
        const newMeshData: MeshTreeDataWithUid = new MeshTreeData(null, this);
        newMeshData.thumbnail = res.file.name;
        newMeshData.thumbnailFiletype = getImageFileType(res.file.name);
        newMeshData.thumbnailUrl = encodedString;
        newMeshData.uid = res.file.uid;
        this.selectedProductImages = [
          ...cloneDeep(this.selectedProductImages),
          newMeshData,
        ];
      });
    }
    if(imageType === ImageType.designLR){
      fileContentToBase64(res.file, (encodedString) => {
          const newMeshData: MeshTreeDataWithUid = new MeshTreeData(null, this);
          newMeshData.thumbnail = res.file.name;
          newMeshData.thumbnailFiletype = getImageFileType(res.file.name);
          newMeshData.thumbnailUrl = encodedString;
          newMeshData.uid = res.file.uid;
          this.selectedDesignImagesLR = [
            ...cloneDeep(this.selectedDesignImagesLR),
            newMeshData,
          ];
        })
    }
    if(imageType === ImageType.designHR){
      fileContentToBase64(res.file, (encodedString) => {
        const newMeshData: MeshTreeDataWithUid = new MeshTreeData(null, this);
        newMeshData.thumbnail = res.file.name;
        newMeshData.thumbnailFiletype = getImageFileType(res.file.name);
        newMeshData.thumbnailUrl = encodedString;
        newMeshData.uid = res.file.uid;
        this.selectedDesignImagesHR = [
          ...cloneDeep(this.selectedDesignImagesHR),
          newMeshData,
        ];
      })
    }
    if(imageType === ImageType.video){
      this.uploadVideoFileToAzure(res)
    }
    return true;
  }

  async uploadVideoFileToAzure(res: any): Promise<boolean> {
    this.uploadVideoLoading = true

    // Get video file
    const videoFile = res.file
    const videoDirectoryPath = `${this.directoryPath}/media/videos`;

    // Generate video thumbnail file 
    const videoThumbnailFile = await generateVideoThumbnail(videoFile);
    const videoThumbnailDirectoryPath = `${this.directoryPath}/media/images`;

    // validation: If there is no videoFile or videoThumbnailFile, 
    // then do not proceed to the next step
    if (!videoFile || !videoThumbnailFile) return false;

    try {
      // Upload video
      const videoUrl = await uploadToAzure({
        file:videoFile,
        direcatoryPath:videoDirectoryPath,
        
      })
      console.log('videoUrl', videoUrl)
      // Upload thumbnail
      const videoThumbnailUrl = await uploadToAzure({
        file:videoThumbnailFile,
        direcatoryPath:videoThumbnailDirectoryPath
      })
      console.log('videoThumbnailUrl', videoThumbnailUrl)

       
      // update state video & thumbnail 
      await this.setVideoFile(videoFile, videoUrl)
      await this.setVideoThumbnailFile(videoThumbnailFile, videoThumbnailUrl)
      await this.setProductVideoFiles(videoFile)

      alert('Upload successful!');
      this.uploadVideoLoading = false
      return true;
    }
    catch (error) {
      console.error('Error uploading files:', error);
      alert('Upload failed. Please try again.');
      this.uploadVideoLoading = false
      return false;
    }
  }

  async setProductVideoFiles(videoFile: FileData): Promise<boolean> {
    if(
      !videoFile) return false;
    
    console.log('videoFile', videoFile?.uid)
    return true;
  }

  async setVideoFile(file: File, videoUrl: string): Promise<boolean> {
    if(!file || !videoUrl.length) return false;
    
    this.selectedInput.video = file.name;
    (this.selectedInput.videoFiletype = getVideoFileType(file.name)),
    (this.selectedInput.videoUrl = videoUrl);
    return true;
  }

  async setVideoThumbnailFile(file: File, videoThumbnailUrl: string): Promise<boolean> {
    if(!file || !videoThumbnailUrl.length) return false;

    this.selectedInput.videoThumbnail = file.name;
    (this.selectedInput.videoThumbnailFiletype = getImageFileType(file.name)),
    (this.selectedInput.videoThumbnailUrl = videoThumbnailUrl);
    return true;
  }

  handleRemove(file: UploadFile, imageType: ImageType): void {
    ElMessageBox.confirm(
      (this as any).$t('components.mesh-loader.deleteMessage'),
      'info',
      {
        confirmButtonText: (this as any).$t('components.mesh-loader.yes'),
        cancelButtonText: (this as any).$t('components.mesh-loader.no'),
        type: 'warning',
      }
    )
      .then(async (confirm) => {
        if (confirm) {
          if(imageType === ImageType.object3D){
            const item = this.selectedInput.treeData.find(
              (x) => x.name === file.uid.toString()
            );
            if (item) {
              this.selectedInput.removeItem(item);
            }
            this.handleSelect(null);
            const newMeshFiles = cloneDeep(this.meshFiles).filter(
              (f) => f.uid !== file?.uid
            );
            this.meshFiles = newMeshFiles;
          }
          if(imageType === ImageType.product){
            this.selectedInput.thumbnail = null;
            this.selectedInput.thumbnailUrl = null;
            this.selectedInput.thumbnailFiletype = null;
            const newImageFiles = cloneDeep(this.productImageFiles).filter(
              (f) => f.uid !== file?.uid
            );
            this.productImageFiles = newImageFiles;

            const newSelectedInputs = cloneDeep(
              this.selectedProductImages
            ).filter((f) => f.uid !== file.uid);
            this.selectedProductImages = newSelectedInputs;
          }
          if(imageType === ImageType.designLR){
            const newImageFiles = cloneDeep(this.designImageFilesLR).filter(
              (f) => f.uid !== file?.uid
            );
            this.designImageFilesLR = newImageFiles;

            const newSelectedInputs = cloneDeep(
              this.selectedDesignImagesLR
            ).filter((f) => f.uid !== file.uid);
            this.selectedDesignImagesLR = newSelectedInputs;
          }
          if(imageType === ImageType.designHR){
            const newImageFiles = cloneDeep(this.designImageFilesHR).filter(
              (f) => f.uid !== file?.uid
            );
            this.designImageFilesHR = newImageFiles;

            const newSelectedInputs = cloneDeep(
              this.selectedDesignImagesHR
            ).filter((f) => f.uid !== file.uid);
            this.selectedDesignImagesHR = newSelectedInputs;
          }
          if(imageType === ImageType.video){
            const uploadNewProductVideoFiles = this.productVideoFiles.filter(({uid}) => uid !== file.uid)
          
            if (this.selectedInput?.videoUrl) await deleteFileFromAzure(this.selectedInput.videoUrl)
            if (this.selectedInput?.videoThumbnailUrl) await deleteFileFromAzure(this.selectedInput?.videoThumbnailUrl)
            
            this.productVideoFiles = uploadNewProductVideoFiles
            this.selectedInput.video = null;
            this.selectedInput.videoUrl = null;
            this.selectedInput.videoFiletype = null;
            this.selectedInput.videoThumbnail = null;
            this.selectedInput.videoThumbnailUrl = null;
            this.selectedInput.videoThumbnailFiletype = null;
          }
        }
      })
      .catch(() => {
        //
      });
  }

  addMesh(file: UploadData | null): void {
    if (file) {
      const fileType = getFileType(file.name) as FileType3D;
      if (fileType !== FileType3D.NONE) {
        this.selected = file.uid;
        if (file.url) {
          const item = this.selectedInput.addImport(
            file.uid.toString(),
            file.name,
            file.url,
            '',
            '',
            null,
            true
          );
          item.bones = this.boneData;
          item.activeBoneIndex = this.activeBoneIndex;
          this.loadingData.url = file.url;
          this.loadingData.fileType = fileType;
        }
      }
    }
  }

  handleSelect(file: UploadData | null): void {
    if (file) {
      const fileType = getFileType(file.name) as FileType3D;
      if (fileType !== FileType3D.NONE) {
        this.selected = file.uid;
        for (const treeData of this.selectedInput.treeData) {
          if (treeData.name == this.selected.toString()) {
            treeData.isSelected = true;
          }
        }
      }
    } else {
      this.selected = -1;
    }
  }

  beforeUpload(file: any): boolean {
    if (isValidFileType(file.name)) {
      return true;
    }
    ElMessage.error((this as any).$t('components.mesh-loader.wrongType'));
    return false;
  }

  beforeImageUpload(file: any): boolean {
    if (isValidImageFileType(file.name)) {
      return true;
    }
    ElMessage.error((this as any).$t('components.mesh-loader.wrongType'));
    return false;
  }

  beforeVideoUpload(file: any): boolean {
    if (isValidVideoFileType(file.name)) {
      if (this.selectedInput.videoUrl == null) {
        return true;
      }
      ElMessage.error((this as any).$t('components.mesh-loader.onlyOneVideo'));
      return false;
    }
    ElMessage.error((this as any).$t('components.mesh-loader.wrongType'));
    return false;
  }
 

  logProductType(value: ProductType): void {
    console.log(value);
  }

  onDesignTypeChange(): void {
    this.designImageFilesHR = [];
    this.selectedDesignImagesHR = [];
    this.designImageFilesLR = [];
    this.selectedDesignImagesLR = [];
  }

  async defaultUndo(): Promise<void> {
    //
  }

  async defaultRedo(): Promise<void> {
    //
  }

  setShowFormBuilder(): void {
    this.formbuilderDialog.show();
  }
}
</script>

<style lang="scss" scoped>
.el-scrollbar::v-deep {
  .el-scrollbar__view {
    margin: 1rem;
  }
}

.el-steps::v-deep {
  &.el-steps--vertical {
    height: unset;
  }

  .el-step__head.is-process {
    color: var(--el-color-primary);
    border-color: var(--el-color-primary);
  }

  .el-step__head.is-finish,
  .el-step__head.is-wait {
    color: var(--el-text-color-primary);
    border-color: var (--el-text-color-primary);
  }
}

.big {
  margin-bottom: 0.5rem;
}

.upload-item {
  overflow: visible;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.selected {
  border: var(--color-primary) 2px solid;
}

.bones {
  min-height: calc(100vh - 7.2rem);
}

.addButton {
  text-align: right;
  margin-top: 1rem;
}

.action {
  color: var(--color-primary);
}

.el-aside {
  z-index: 1;
}
</style>
