<script lang="ts">
import PreviewRendererImg from "@/components/three/PreviewRendererImg.vue";
import { Options, Vue } from "vue-class-component";
import { OrderRequestData } from "@/services/api/orderService";
import { Emit, Prop } from "vue-property-decorator";
import { WorkflowModel } from "@/types/ui/WorkflowModel";
import PreviewRenderer from "@/components/three/PreviewRenderer.vue";
import { MeshTreeData, MeshTreeDataGroupItem, MeshTreeDataItem } from "@/types/ui/MeshTreeData";
import { Product } from "@/types/api/Model/Product";
import ProductParameterBalcony from "./productParameters/ProductParameterBalcony.vue";
import ProductParameterCover from "./productParameters/ProductParameterCover.vue";
import ProductParameterCuboid from "./productParameters/ProductParameterCuboid.vue";
import ProductParameterFence from "./productParameters/ProductParameterFence.vue";
import ProductParameterTemplate from "./productParameters/ProductParameterTemplate.vue";
import ProductParameterOrthesis from "./productParameters/ProductParameterOrthesis.vue";
import ProductParameterLinearLight from "@/components/productParameters/ProductParameterLinearLight.vue";
import ProductParameterBathtub from "@/components/productParameters/ProductParameterBathtub.vue";
import Auth from "@/services/api/auth";
import { Role } from "@/types/enum/user";
import * as modelService from '@/services/api/modelService';
import { OrientationType } from "@/types/enum/order";
import { LinearLightNameType } from "@/types/enum/order";
import html2pdf from 'html2pdf.js';
import { createPdfOfferTemplate, createPdfPartListTemplate } from '@/utils/pdfTemplates'
import i18n from '@/i18n'; 
import de from '@/locales/de.json'
import en from '@/locales/en.json'


@Options({
  components: {
    PreviewRendererImg,
    PreviewRenderer,
    ProductParameterBalcony,
    ProductParameterCover,
    ProductParameterCuboid,
    ProductParameterFence,
    ProductParameterTemplate,
    ProductParameterOrthesis,
    ProductParameterLinearLight,
    ProductParameterBathtub
  }
})

export default class OrderSummary extends Vue {
  @Prop() order!: OrderRequestData;
  @Prop() workflowModel!: WorkflowModel;
  @Prop() meshTreeData!: MeshTreeData;
  @Prop({ default: [] }) treeData!: MeshTreeDataItem[];
  @Prop({ default: [] }) colorList!: string[];
  @Prop({ default: [] }) embossingList!: string[];
  @Prop({ default: [] }) embossingColorList!: string[];
  @Prop() product!: Product;

  locale = i18n?.getLocale() === 'en' ? en : de;

  RegionalEnergyYield = 950;
  EuroPerKWh = 0.40;

  authentication = Auth.getInstance();
  Role = Role;
  savingsPerYear = 0.0;
  amortisation = 0;
  calculatingAmortisation = false;

  LinearLightNameType = LinearLightNameType;

  meshGroupedPartItems: {[key: string]: MeshTreeDataGroupItem[]} = {};

  mounted(): void {
    this.meshTreeData.getGroupedPartItems(false).then((list) => {
      this.meshGroupedPartItems = list;
    })
    this.calculateAmortisation();
  }

  @Emit('update:calculatingAmortisation')
  private toggleCalculateAmortisation(status: boolean): boolean {
    this.calculatingAmortisation = status;
    return this.calculatingAmortisation; // Emit calculatingAmortisation value to parent
  }

  async calculateAmortisation() {
    this.toggleCalculateAmortisation(true)
    let wp = 0.0;
    for(const treeData of this.meshTreeData.treeData.filter(m => m.componentId != null)) {
      const power = await modelService.getPower(treeData.componentId!);
      if (power) {
        wp += power.value;
      }
    }
    const kWp = wp / 1000;

    let personalEnergyYield = this.RegionalEnergyYield;
    switch(this.order.orientationType) {
      case OrientationType.south: {
        personalEnergyYield *= 1; // 100 %
        break;
      }
      case OrientationType.eastOrWest: {
        personalEnergyYield *= 0.8; // 80%
        break;
      }
    }

    const kWh = kWp * personalEnergyYield;

    this.savingsPerYear = kWh * this.EuroPerKWh;
    this.amortisation = this.workflowModel.totalPrice / this.savingsPerYear;
    this.toggleCalculateAmortisation(false)
  }

  // Create Offer Pdf Content
  downloadOfferPDF(): void {
    if(!this.product) return; // if the product does not exist, do not continue execution 

    const imageLogo = this.authentication.domains[0].logo;
    const title = this.locale.components['order-summary'].offer;
    const imageProduct = this.product?.thumbnail.content;
    const prouductName = this.order.coverModel
    const productPrice = this.workflowModel.totalPrice.toFixed(2);
    const withZip = this.order.zip === 'yes' 
      ? this.locale.enum.zipType.yes
      : this.locale.enum.zipType.no
    const sewInByCustomer = this.order.sewInByCustomer 
      ? this.locale.views.customize.workflow.yes 
      : this.locale.views.customize.workflow.no;
    const cosmeticEndingAtFoot = this.order.cosmeticEndingAtFoot 
      ? this.locale.views.customize.workflow.yes 
      : this.locale.views.customize.workflow.no;
    let productDescription = '';

    if(this.product?.type.name.toLowerCase() === 'covers') {
      productDescription = `
        ${this.locale.components['order-form'].bodySide}: ${this.locale.enum.bodySide[this.order.bodySide]}; <br>
        ${this.locale.components['order-form'].kneeJoint}: ${this.order.kneeJoint} ${withZip}; <br>
        ${this.locale.views.customize.workflow.form.sewInByCustomer}: ${sewInByCustomer}; <br>
        ${this.locale.views.customize.workflow.form.cosmeticEndingAtFoot}: ${cosmeticEndingAtFoot}; <br>
        ${this.locale.components["order-form"]["scope-top"]}: ${this.order.depth};<br> 
        ${this.locale.components["order-form"].height}: ${this.order.heightWithoutDeduction};<br>
        ${this.locale.components["order-form"]["scope-bottom"]}: ${this.order.calfCircumferenceWithoutDeduction};<br>
        ${this.locale.components["order-summary"].embossing}: ${this.embossingList.join(', ')}; <br>
        ${this.locale.components["order-summary"].baseColor}: ${this.colorList.join(', ')}
      `
    }
    if(this.product?.type.name.toLowerCase() === 'balkone') {
      productDescription = `
        ${this.locale.components["product-parameter"].balcony["carrying-out"]}: ${this.locale.enum.balconyType[this.order.balconyType]}; <br> 
        ${this.locale.components["product-parameter"].balcony["mounting-type"]}: ${this.locale.enum.mountingType[this.order.mountingType]}; <br> 
        ${this.locale.components["product-parameter"].balcony["paneel-type"]}: ${this.locale.enum.paneelType[this.order.paneelType]}; <br> 
        ${this.locale.components["order-form"].width}: ${this.order.calfCircumferenceWithoutDeduction};<br> 
        ${this.locale.components["order-form"].height}: ${this.order.heightWithoutDeduction};<br> 
        ${this.locale.components["order-form"].depth}: ${this.order.depth}<br> 
      `
    }
    if(
      this.product.type.name.toLowerCase() == 'orthesen' &&
      !this.product.name.replace(/\s/g, '').toLowerCase().includes('phase1') 
    ) {
      const zip = 
        this.product?.name?.toLowerCase().includes('u-flex') || 
        this.product?.name?.toLowerCase().includes('c-flex')
        ? withZip
        : ''

      productDescription = `
        ${this.locale.components['order-form'].bodySide}: ${this.locale.enum.bodySide[this.order.bodySide]}; <br>
        ${this.locale.components['order-form'].kneeJoint}: ${this.order.kneeJoint} ${zip}; <br>
        ${this.locale.components["order-form"].depth}: ${this.order.depth};<br>
        ${this.locale.components["order-form"].height}: ${this.order.heightWithoutDeduction};<br>
        ${this.locale.components["order-form"].width}: ${this.order.calfCircumferenceWithoutDeduction};<br>
        ${this.locale.components["order-form"].foot}: ${this.order.foot}; <br>
        ${this.locale.views.customize.workflow.form.scanFiles}: ${this.order.scanFiles.join(', ')}
      `
    }
    if(
      this.product.type.name.toLowerCase() == 'möbel' ||
      this.product.type.name.toLowerCase() == 'autos' ||
      this.product.type.name.toLowerCase() == 'gebäude'
    ) {
      productDescription = `
        ${this.locale.components["order-form"].width}: ${this.order.calfCircumferenceWithoutDeduction}; <br>
        ${this.locale.components["order-form"].height}: ${this.order.heightWithoutDeduction};<br>
        ${this.locale.components["order-form"].depth}: ${this.order.depth};<br>
      `
    }
    if(this.product.type.name.toLowerCase() == 'zäune') {
      productDescription = `
        ${this.locale.components["product-parameter"].fence["carrying-out"]}: ${this.locale.enum.balconyType[this.order.balconyType]}; <br>
        ${this.locale.components["order-form"].width}: ${this.order.calfCircumferenceWithoutDeduction};<br>
        ${this.locale.components["order-form"].height}: ${this.order.heightWithoutDeduction};<br>
        ${this.locale.components["order-form"].depth}: ${this.order.depth}
      `
    }
    if(this.product.type.name.toLowerCase() == 'lineare beleuchtung') {
      console.log('lineare beleuchtung')
    }

    // Create html pdf template 
    const content = createPdfOfferTemplate(
      imageLogo, 
      title, 
      imageProduct,
      prouductName,
      productPrice,
      productDescription,
    );

    // Generate pdf
    this.generatePDF(content, 'offer')
  }

  // Create Part List Pdf Content
  downloadPartListPDF(): void {  
    if(!this.product) return; // if the product does not exist, do not continue execution 

    const imageLogo = this.authentication.domains[0].logo;
    const title = this.locale.components['order-summary'].parts;
    const partList = Object.entries(this.meshGroupedPartItems).map(part => {
      let label = '';

      if(this.product.type.name.toLowerCase() === 'covers') {
        label = this.locale.components['part-selection'][this.product.type.name.toLowerCase()][part[0]]
          ? part[1].reduce((sum, current) => sum + current.cnt, 0) + 'x ' + this.locale.components['part-selection'][this.product.type.name.toLowerCase()][part[0]]
          : part[1].length + 'x ' + part[0]
      }
      else {
        label = this.locale.components['part-selection'][this.product.type.name.toLowerCase()]?.part[0]
          ? part[1].reduce((sum, current) => sum + current.cnt, 0) + 'x ' + this.locale.components['part-selection'][this.product.type.name.toLowerCase()]?.part[0]
          : part[1].length + 'x ' + part[0]
      }

      const parts = part[1].map(item => {
        return ({
          width: Math.ceil(item.meshTreeDataItem.size?.width ?? 0) + ' mm',
          height: Math.ceil(item.meshTreeDataItem.size?.height ?? 0) + ' mm',
          depth: Math.ceil(item.meshTreeDataItem.size?.depth ?? 0) + ' mm',
          cnt: item.cnt,
          basePrice: item.meshTreeDataItem.basePrice,
          totalPrice: item.cnt * item.meshTreeDataItem.basePrice,
          thumbnailUrl: item.meshTreeDataItem.thumbnailUrl
        })
      })
        
      return ({label, parts})
    })

    // Create html pdf template 
    const content = createPdfPartListTemplate(
      imageLogo, 
      title, 
      partList
    )

    // Generate PDF 
    this.generatePDF(content, 'part-list')
  }

  // Generate PDF
  generatePDF(content: HTMLElement, filename: string): void {
    const options = {
      margin: [14, 14, 14, 14], // Set margin to 14 to fit all content
      filename: filename + '.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { 
        scale: 2, 
        useCORS: true, // Use CORS if necessary
        scrollX: 0, // Make sure there is no scroll
        scrollY: 0
      },
      jsPDF: { 
        unit: 'mm', 
        format: 'a4', 
        orientation: 'portrait' 
      },
    };

    // HTML to PDF conversion process
    html2pdf().from(content).set(options).save();
  }
}

</script>

<template>
  <div id="order-summary">
    <h2>{{ $t('components.order-summary.title') }}</h2>

    <div v-if="!authentication.domains.some(d => d.urlSuffix == 'wjtortho')">

      <!-- Product data -->
      <h3 class="mt-3">{{ $t('components.order-summary.productDataTitle') }}</h3>
      <div class="row1">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.product')}}</label>
        <label class="col form-label mb-1">{{ order?.coverModel }}</label>
      </div>
      <div class="row1" v-if="colorList.length > 0 && !product.name.toLowerCase().includes('s-cover')">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.baseColor')}}</label>
        <label class="col form-label mb-1">{{ colorList.join(', ') }}</label>
      </div>
      <div class="row1" v-if="embossingList.length > 0">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.embossing')}}</label>
        <label class="col form-label mb-1">{{ embossingList.join(', ') }}</label>
      </div>
      <div class="row1" v-if="embossingColorList.length > 0">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.embossingColor')}}</label>
        <label class="col form-label mb-1">{{ embossingColorList.join(', ') }}</label>
      </div>
      <div class="row1" v-if="authentication.domains.some(d => d.urlSuffix == 'festwood')">
        <label class="col form-label text-secondary mb-1">{{ $t('components.color-footer.meshVarnishButton')}}</label>
        <label class="col form-label mb-1">{{ $t(`views.varnish-styles.${order?.varnish}`) }}</label>
      </div>

      <!-- Contact data -->
      <h3 class="mt-3">{{ $t('components.order-summary.contactDataTitle') }}</h3>
      <div>
        <div class="row1" v-if="!authentication.domains.some(d => d.urlSuffix == 'luttermann')">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.contactPerson')}}</label>
          <label class="col form-label mb-1">{{ order?.technicianFirstname + " " + order?.technicianLastname }}</label>
        </div>
        <div class="row1">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.company') }}</label>
          <label class="col form-label mb-1">{{ order?.company }}</label>
        </div>
        <div class="row1" v-if="authentication.domains.some(d => d.urlSuffix == 'luttermann')">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.orderer')}}</label>
          <label class="col form-label mb-1">{{ order?.technicianFirstname + " " + order?.technicianLastname }}</label>
        </div>
        <div class="row1" v-if="authentication.domains.some(d => d.urlSuffix == 'luttermann')">
          <!-- Show customer number for Luttermann -->
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.technician') }}</label>
          <label class="col form-label mb-1">{{ order?.technician }}</label>
        </div>
        <div class="row1" v-if="authentication.domains.some(d => d.urlSuffix == 'luttermann')">
          <!-- Show commission number if not Luttermann -->
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.vg-number') }}</label>
          <label class="col form-label mb-1">{{ order?.vgNumber }}</label>
        </div>
        <div class="row1" v-if="authentication.domains.some(d => d.urlSuffix == 'luttermann')">
          <!-- Show customer number for Luttermann -->
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.vsg-number') }}</label>
          <label class="col form-label mb-1">{{ order?.vsgNumber }}</label>
        </div>
        <div class="row1" v-if="authentication.domains.some(d => d.urlSuffix == 'luttermann')">
          <!-- Show customer number for Luttermann -->
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.customerNr') }}</label>
          <label class="col form-label mb-1">{{ order?.customerNr }}</label>
        </div>
        <div class="row1">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.mail') }}</label>
          <label class="col form-label mb-1">{{ order?.mail }}</label>
        </div>
      </div>

      <!-- Parameter -->
      <product-parameter-cover
        v-if="product.type.name.toLowerCase() == 'covers'"
        :properties="[order]"
        :product="product"
        :readonly="true"
      />
      <!-- TODOS: ADJUST IF PROPS -->
      <product-parameter-template
        v-if="product.name.replace(/\s/g, '').toLowerCase().includes('phase1')"
        :properties="[order]"
        :product="product"
        :readonly="true"
      />

      <!-- TODOS: ADJUST IF PROPS -->
      <!-- v-if="product.type.name.toLowerCase() == 'orthesen'" -->
      <product-parameter-orthesis
        v-if=" product.type.name.toLowerCase() == 'orthesen' &&
        !product.name.replace(/\s/g, '').toLowerCase().includes('phase1')
      "
        :properties="[order]"
        :product="product"
        :readonly="true"
      />
      <product-parameter-cuboid
        v-if="product.type.name.toLowerCase() == 'möbel' ||
              product.type.name.toLowerCase() == 'autos' ||
              product.type.name.toLowerCase() == 'gebäude'"
        :properties="[order]"
        :readonly="true"
      />
      <product-parameter-balcony
        v-if="product.type.name.toLowerCase() == 'balkone'"
        :properties="[order]"
        :readonly="true"
      />
      <product-parameter-fence
        v-if="product.type.name.toLowerCase() == 'zäune'"
        :properties="[order]"
        :readonly="true"
      />
      <product-parameter-linear-light
        v-if="product.type.name.toLowerCase() == 'lineare beleuchtung'"
        :properties="[order]"
        :isHangingLight="product.name.toLowerCase() === LinearLightNameType.hanging.toLowerCase()"
        :isSurfaceMountedLight="product.name.toLowerCase() === LinearLightNameType.surfaceMounted.toLowerCase()"
        :readonly="true"
      />

      <product-parameter-bathtub
        v-if="product.type.name.toLowerCase() == 'duschen und bäder'"
        :properties="[order]"
        :readonly="true"
      />

      <!-- Part list -->
      <div v-if="(authentication?.roles?.includes(Role.Supplier) || authentication?.roles?.includes(Role.Reseller)) && !product?.name.toLowerCase().includes('cover')">
          <h3 class="mt-3">{{ $t('components.order-summary.parts') }}</h3>
          <div class="row1" v-for="part in Object.entries(meshGroupedPartItems)" :key="part[0]">
            <label class="col form-label mb-1">{{ $t('components.part-selection.' + product.type.name.toLowerCase() + '.' + part[0]) ? part[1].reduce((sum, current) => sum + current.cnt, 0) + 'x ' + $t('components.part-selection.' + product.type.name.toLowerCase() + '.' + part[0]) : part[1].length + 'x ' + part[0] }}</label>
            <div v-for="item in part[1]" :key="item.meshTreeDataItem.name">
              <label v-if="item.meshTreeDataItem.size?.width" class="col form-label text-secondary mb-1">{{ item.cnt + 'x ' + Math.ceil(item.meshTreeDataItem.size?.width) + ' mm'}}</label>
              <label v-if="item.meshTreeDataItem.size?.height" class="col form-label text-secondary mb-1">{{ Math.ceil(item.meshTreeDataItem.size?.height) + ' mm'}}</label>
              <label v-if="item.meshTreeDataItem.size?.depth" class="col form-label text-secondary mb-1">{{ Math.ceil(item.meshTreeDataItem.size?.depth) + ' mm'}}</label>
            </div>
          </div>
      </div>

      <!-- Remark -->
      <h3 class="mt-3">{{ $t('components.order-form.remarks') }}</h3>
      <div class="row border-0">
        <div class="col mb-2">
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.remarks') }}</label>
            <label class="col form-label mb-1">{{ order?.remarks }}</label>
          </div>
        </div>
      </div>

      <!-- Price -->
      <div v-if="authentication.user !== null || !this.product.name?.toLocaleLowerCase().includes('cover')">
        <h3 class="mt-3">{{ $t('components.order-summary.priceTitle') }}</h3>
        <div class="row border-0">
          <div class="col mb-2">
            <div class="row1">
              <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.productPrice') }}</label>
              <label class="col form-label mb-1">{{ workflowModel.totalPrice.toFixed(2) }} €</label>
            </div>
            <!-- <div class="row1">
              <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.ust') }}</label>
              <label class="col form-label mb-1">{{ (workflowModel.totalPrice * 0.2).toFixed(2) }} €</label>
            </div>
            <div class="row1">
              <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.totalPrice') }}</label>
              <label class="col form-label mb-1">{{ (workflowModel.totalPrice * 1.2).toFixed(2) }} €</label>
            </div> -->
          </div>
        </div>
      </div>
      <div v-else class="mt-4">
        <h2 class="text-center">{{ $t('components.order-summary.loginToOrder') }}</h2>
      </div>

      <!-- Delivery -->
      <div v-if="product.type.name.toLowerCase() == 'balkone'" class="row border-0">
        <div class="col mb-2">
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.balcony.price-information') }}</label>
          </div>
        </div>
      </div>
      <!-- <div v-else class="row border-0">
        <div class="col mb-2">
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.shippingCosts') }}: {{ $t('components.order-summary.shippingCostsInfo') }}</label>
          </div>
        </div>
      </div> -->

      <!-- Amortisation -->
      <h3 v-if="product.type.name.toLowerCase() == 'balkone'" class="mt-3">{{ $t('components.order-summary.amortisation') }}</h3>
      <div v-loading="calculatingAmortisation" v-if="product.type.name.toLowerCase() == 'balkone'" class="row border-0">
        <div class="col mb-2">
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.product-parameter.balcony.orientation-type') }}</label>
            <label class="col form-label mb-1">{{ $t(`enum.orientationType.${order?.orientationType}`) }}</label>
          </div>
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.savings-per-year') }}</label>
            <label class="col form-label mb-1">{{ (savingsPerYear).toFixed(2) }} €</label>
          </div>
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.amortisation') }}</label>
            <label class="col form-label mb-1">{{ amortisation <= 30 ? (amortisation).toFixed(0) : '+30' }} {{ $t('components.order-summary.years') }}</label>
          </div>
        </div>
      </div>
    </div>

    <!--Teufel-Ansicht-->

    <div v-if="authentication.domains.some(d => d.urlSuffix == 'wjtortho')">

      <!-- Product data -->
      <h3 class="mt-3">{{ $t('components.order-summary.productDataTitle') }}</h3>
      <div class="row1">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.product')}}</label>
        <label class="col form-label mb-1">{{ order?.coverModel }}</label>
      </div>
      <div class="row1" v-if="colorList.length > 0 && !product.name.toLowerCase().includes('s-cover')">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.baseColor')}}</label>
        <label class="col form-label mb-1">{{ colorList.join(', ') }}</label>
      </div>
      <div class="row1" v-if="embossingList.length > 0">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.embossing')}}</label>
        <label class="col form-label mb-1">{{ embossingList.join(', ') }}</label>
      </div>
      <div class="row1" v-if="embossingColorList.length > 0">
        <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.embossingColor')}}</label>
        <label class="col form-label mb-1">{{ embossingColorList.join(', ') }}</label>
      </div>

      <!-- Contact data -->
      <h3 class="mt-3">{{ $t('components.order-summary.contactDataTitle') }}</h3>
      <div>
        <div class="row1">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.company') }}</label>
          <label class="col form-label mb-1">{{ order?.company }}</label>
        </div>
        <div class="row1">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.customerNr') }}</label>
          <label class="col form-label mb-1">{{ order?.customerNr }}</label>
        </div>
        <div class="row1">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.orderNr') }}</label>
          <label class="col form-label mb-1">{{ order?.orderNr }}</label>
        </div>
        <div class="row1">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.billingNr') }}</label>
          <label class="col form-label mb-1">{{ order?.billingNr }}</label>
        </div>
        <div class="row1">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.contactPerson')}}</label>
          <label class="col form-label mb-1">{{ order?.technicianFirstname + " " + order?.technicianLastname }}</label>
        </div>
        <div class="row1">
          <label class="col form-label text-secondary mb-1">{{ $t('views.customize.workflow.form.mail') }}</label>
          <label class="col form-label mb-1">{{ order?.mail }}</label>
        </div>
      </div>

      <!-- Parameter -->
      <product-parameter-cover
        v-if="product.type.name.toLowerCase() == 'covers'"
        :properties="[order]"
        :product="product"
        :readonly="true"
      />

      <!-- Part list -->
      <div v-if="(authentication?.roles?.includes(Role.Supplier) || authentication?.roles?.includes(Role.Reseller)) && !product?.name.toLowerCase().includes('cover')">
          <h3 class="mt-3">{{ $t('components.order-summary.parts') }}</h3>
          <div class="row1" v-for="part in Object.entries(meshGroupedPartItems)" :key="part[0]">
            <label class="col form-label mb-1">{{ $t('components.part-selection.' + product.type.name.toLowerCase() + '.' + part[0]) ? part[1].reduce((sum, current) => sum + current.cnt, 0) + 'x ' + $t('components.part-selection.' + product.type.name.toLowerCase() + '.' + part[0]) : part[1].length + 'x ' + part[0] }}</label>
            <div v-for="item in part[1]" :key="item.meshTreeDataItem.name">
              <label v-if="item.meshTreeDataItem.size?.width" class="col form-label text-secondary mb-1">{{ item.cnt + 'x ' + Math.ceil(item.meshTreeDataItem.size?.width) + ' mm'}}</label>
              <label v-if="item.meshTreeDataItem.size?.height" class="col form-label text-secondary mb-1">{{ Math.ceil(item.meshTreeDataItem.size?.height) + ' mm'}}</label>
              <label v-if="item.meshTreeDataItem.size?.depth" class="col form-label text-secondary mb-1">{{ Math.ceil(item.meshTreeDataItem.size?.depth) + ' mm'}}</label>
            </div>
          </div>
      </div>

      <!-- Remark -->
      <h3 class="mt-3">{{ $t('components.order-form.remarks') }}</h3>
      <div class="row border-0">
        <div class="col mb-2">
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-form.remarks') }}</label>
            <label class="col form-label mb-1">{{ order?.remarks }}</label>
          </div>
        </div>
      </div>

      <!-- Price -->
      <div v-if="authentication.user !== null || !this.product.name?.toLocaleLowerCase().includes('cover')">
        <h3 class="mt-3">{{ $t('components.order-summary.priceTitle') }}</h3>
        <div class="row border-0">
          <div class="col mb-2">
            <div class="row1">
              <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.productPrice') }}</label>
              <label class="col form-label mb-1">{{ workflowModel.totalPrice.toFixed(2) }} €</label>
            </div>
            <!-- <div class="row1">
              <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.ust') }}</label>
              <label class="col form-label mb-1">{{ (workflowModel.totalPrice * 0.2).toFixed(2) }} €</label>
            </div>
            <div class="row1">
              <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.totalPrice') }}</label>
              <label class="col form-label mb-1">{{ (workflowModel.totalPrice * 1.2).toFixed(2) }} €</label>
            </div> -->
          </div>
        </div>
      </div>
      <div v-else class="mt-4">
        <h2 class="text-center">Um Preise einzusehen und Bestellungen aufzugeben ist eine Registrierung notwendig!</h2>
      </div>

      <!-- Delivery -->
      <div v-if="product.type.name.toLowerCase() == 'balkone'" class="row border-0">
        <div class="col mb-2">
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.balcony.price-information') }}</label>
          </div>
        </div>
      </div>
       <!-- <div v-else class="row border-0">
        <div class="col mb-2">
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.shippingCosts') }}: {{ $t('components.order-summary.shippingCostsInfo') }}</label>
          </div>
        </div>
      </div> -->

      <!-- Amortisation -->
      <h3 v-if="product.type.name.toLowerCase() == 'balkone'" class="mt-3">{{ $t('components.order-summary.amortisation') }}</h3>
      <div v-loading="calculatingAmortisation" v-if="product.type.name.toLowerCase() == 'balkone'" class="row border-0">
        <div class="col mb-2">
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.product-parameter.balcony.orientation-type') }}</label>
            <label class="col form-label mb-1">{{ $t(`enum.orientationType.${order?.orientationType}`) }}</label>
          </div>
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.savings-per-year') }}</label>
            <label class="col form-label mb-1">{{ (savingsPerYear).toFixed(2) }} €</label>
          </div>
          <div class="row1">
            <label class="col form-label text-secondary mb-1">{{ $t('components.order-summary.amortisation') }}</label>
            <label class="col form-label mb-1">{{ amortisation <= 30 ? (amortisation).toFixed(0) : '+30' }} {{ $t('components.order-summary.years') }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped lang="scss">
.h2, .h3,
.text-secondary {
  color: #0000008D !important;
}
.form-label {
  width: 20rem;
}
.row {
  border-bottom: 0.1rem solid #0000008D;
}
.row1 {
  border-bottom: 0.1rem solid #6767678d;
}
</style>
